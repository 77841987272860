import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { IsvReportResponse, MyHttpApi, PrivilegeItem, ReportIsvReportInternalRequest, WebStore } from 'utils/api';
import { getSelectedCorporation } from 'utils/corporation-util';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';


@autoinject
export class AdminReportIsvReport extends BaseRoute {
  @observable({ changeHandler: "search" })
  private startDate?: Date;
  @observable({ changeHandler: "search" })
  private endDate?: Date;
  @observable({ changeHandler: "search" })
  private storeId?: number;
  @observable({ changeHandler: "search" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private businessGroupId?: number;
  @observable({ changeHandler: "search" })
  private corporationId?: number;

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.reportIsvReport(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  private data: IsvReportResponse[] = [];

  private fieldList: FieldSpec[] = [];
  private storeList: WebStore[] = [];
  private lastTimestamp?: Date;
  private privilege?: PrivilegeItem;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
    super();
  }

  override async activate() {
    this.privilege = getSelectedCorporation();
    [this.storeList] = await Promise.all([
      this.api.webStoreListAll(),
    ]);
    this.fieldList = [
      { key: "clientName", header: "unit.unit", type: "text" },
      { key: "storeName", header: "isvReport.webStore", type: "text" },
      { key: "storeType", header: "isvReport.storeType", type: "text", },
      { key: "total", header: "isvReport.total", type: "number", minimumFractionDigits: 2 },
      { key: "vivaWalletIsvAmountViaCents", header: "isvReport.vivaWalletIsvAmountViaCents", type: "number", minimumFractionDigits:2 },
      { key: "vivaWalletIsvAmountViaPercentage", header: "isvReport.vivaWalletIsvAmountViaPercentage", type: "number", minimumFractionDigits:2 },
      { key: "vivaWalletIsvAmount", header: "isvReport.vivaWalletIsvAmount", type: "number", minimumFractionDigits:2 }
    ];
    await this.search();
  }

  protected override routeParams() {
    return {
      "storeId": Number,
      "clientId": Number,
      "businessGroupId": Number,
      "corporationId": Number,
      "startDate": Date,
      "endDate": Date,
    };
  }

  buildQueryParams(): ReportIsvReportInternalRequest {
    return {
      clientId: this.clientId,
      businessGroupId: this.businessGroupId,
      corporationId: this.corporationId,
      startDate: this.startDate,
      endDate: this.endDate,
      storeId: this.storeId,
      lang: this.i18n.getLocale(),
    };
  }

  async search() {
    await Promise.all([
      this.ecs.search(),
      this.api.webOrderLastOrderTimestamp().then(x => this.lastTimestamp = x.stateTime),
    ]);
  }

  applyData(data: IsvReportResponse[]) {
    this.data = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  get storeListDynamic() {
    if (this.clientId) {
      return this.storeList.filter(x => this.clientId === x.clientId);
    }
    return [];
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (this.privilege?.id) {
      if (this.privilege.type == 'CLIENT') {
        this.clientId = this.privilege.id;
        this.businessGroupId = this.corporationId = undefined;
      }else if (this.privilege.type == 'BUSINESS_GROUP') {
        this.businessGroupId = this.privilege.id;
        this.clientId = this.corporationId = undefined;
      } else if (this.privilege.type == 'CORPORATION') {
        this.corporationId = this.privilege.id;
        this.clientId = this.businessGroupId = undefined;
      }
    } else {
      this.clientId = this.businessGroupId = this.corporationId = undefined;
    }
    return "";
  }
}
